import React, { useCallback, useContext, useEffect, useState } from 'react'
import AppContext from '../../../context/AppContext'
import getUserMappingAndDashboardList from '../use-cases/getUserMappingAndDashboardList'
import getQuickSightTopicURL from '../use-cases/getQuickSightTopicURL'
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'
import getThemePalette from '../../helpers/getThemePalette'

const embeddingContext = await createEmbeddingContext()

const QuickSightTopic = () => {
  const { repoFactory, selectedYear, theme } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [topicList, setTopicList] = useState([])
  const [url, setUrl] = useState()
  const [elementAccountList, setElementAccountList] = useState([])
  const [embeddedDashboardExperience, setEmbeddedDashboardExperience] = useState()
  let embeddedDashboard

  useEffect(() => {
    setIsLoading(true)
    getUserMappingAndDashboardList(
      {
        token: localStorage.getItem('authToken'),
        year: selectedYear
      },
      {
        biRepo: repoFactory.biRepo(),
        observer: {
          errorReceivingData: () => {
            setIsLoading(false)
          },
          receiveData: ({ qsDashboardObj, elementAccountList }) => {
            setIsLoading(false)
            setElementAccountList(elementAccountList)
            setTopicList(
              qsDashboardObj.dashboardList
                .filter((item) => item?.type === 'Topic')
                .map((item) => ({
                  value: item.value,
                  label: item.name
                }))
            )
          }
        }
      }
    )
  }, [repoFactory, selectedYear])

  // useEffect(() => {
  //   if (embeddedDashboardExperience) {
  //     embeddedDashboardExperience
  //       .setThemeOverride(getThemePalette(theme))
  //       .then((response) => {})
  //       .catch((error) => {
  //         console.error(error)
  //       })
  //   }
  // }, [theme, embeddedDashboardExperience])

  useEffect(() => {
    if (topicList && elementAccountList && topicList.length > 0) {
      setIsLoading(true)
      getQuickSightTopicURL(
        {
          accountIdList: elementAccountList.map((item) => item.accountId).join(),
          topicId: topicList[0].value
        },
        {
          biRepo: repoFactory.biRepo(),
          observer: {
            errorGettingUrl: (error) => {
              setIsLoading(false)
            },
            receiveQuickSightUrl: (response) => {
              setUrl(response.EmbedUrl)
              setIsLoading(false)
            }
          }
        }
      )
    }
  }, [topicList, elementAccountList])

  useEffect(() => {
    if (url) {
      if (embeddedDashboardExperience) {
        document.getElementById('topic-container').removeChild(document.getElementById('topic-container').lastChild)
        setEmbeddedDashboardExperience(null)
      }
      updateDashboard(url)
    }
  }, [url])

  const updateDashboard = useCallback(
    async (url) => {
      try {
        const frameOptions = {
          url,
          container: '#topic-container',
          resizeHeightOnSizeChangedEvent: true,
          width: '100%',
          onChange: (changeEvent, metadata) => {
            switch (changeEvent.eventName) {
              case 'FRAME_MOUNTED': {
                console.log('Do something when the experience frame is mounted.')
                break
              }
              case 'FRAME_LOADED': {
                console.log('Do something when the experience frame is loaded.')

                break
              }
            }
          }
        }

        const contentOptions = {
          // locale: 'en-US',
          panelOptions: {
            panelType: 'SEARCH_BAR',
            focusedHeight: '70px',
            expandedHeight: '500px'
          },
          hideTopicName: true,
          hideIcon: true,
          showTopicName: false,
          showPinboard: false,
          allowTopicSelection: false,
          allowFullscreen: true,
          // searchPlaceholderText: 'custom search placeholder',
          // themeOptions: {
          //   themeOverride: getThemePalette(theme)
          // },
          onMessage: async (messageEvent, experienceMetadata) => {
            switch (messageEvent.eventName) {
              case 'Q_SEARCH_OPENED': {
                console.log('Do something when SEARCH_BAR type panel is expanded')
                break
              }
              case 'Q_SEARCH_FOCUSED': {
                console.log('Do something when SEARCH_BAR type panel is focused')
                break
              }
              case 'Q_SEARCH_CLOSED': {
                console.log('Do something when SEARCH_BAR type panel is collapsed')
                break
              }
              case 'Q_PANEL_ENTERED_FULLSCREEN': {
                console.log('Do something when panel enters full screen mode')
                break
              }
              case 'Q_PANEL_EXITED_FULLSCREEN': {
                console.log('Do something when panel exits full screen mode')
                break
              }
              case 'CONTENT_LOADED': {
                console.log('All visuals are loaded. The title of the document:', messageEvent.message)
                setEmbeddedDashboardExperience(embeddedDashboard)
                break
              }
              case 'ERROR_OCCURRED': {
                console.log(
                  'Error occurred while rendering the experience. Error code:',
                  messageEvent.message.errorCode
                )
                setEmbeddedDashboardExperience(embeddedDashboard)
                break
              }
            }
          }
        }

        embeddedDashboard = await embeddingContext.embedGenerativeQnA(frameOptions, contentOptions)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    },
    [url, setEmbeddedDashboardExperience]
  )
  return <div id="topic-container"></div>
}

export default QuickSightTopic
